<template>
  <div :style="{ backgroundColor: color, width: width + 'px' }" class="presets">
    <v-btn v-for="(button, index) in presets" :key="index" @click="applyPreset(button.method)" class="mb-3" text x-small>
      {{ button.title }}
    </v-btn>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'DatePickerPresets',

  props: {
    color: {
      type: String,
      default: '#ffffff',
    },
    width: {
      type: Number,
      default: 200,
    },
  },

  data: () => ({
    presets: [
      {
        title: 'Цей день',
        method: 'selectCurrentDay',
      },
      {
        title: 'Цей тиждень',
        method: 'selectCurrentWeek',
      },
      {
        title: 'Минулий тиждень',
        method: 'selectLastWeek',
      },
      {
        title: 'Два минулих тиждня',
        method: 'select2LastWeek',
      },
      {
        title: '10 минулих днів',
        method: 'select10LastDays',
      },
      {
        title: 'Цей місяць',
        method: 'selectCurrentMonth',
      },
      {
        title: 'Минулий місяць',
        method: 'selectLastMonth',
      },
      {
        title: '3 минулих місяця',
        method: 'select3LastMonth',
      },
      {
        title: 'За весь час',
        method: 'selectAllTime',
      },
    ],
    datePreset: [],
    // currentDay: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    currentDay: moment().format('YYYY-MM-DD'),
  }),

  methods: {
    applyPreset(methodName) {
      this[methodName]()
    },
    selectCurrentDay() {
      this.datePreset = [this.currentDay, this.currentDay]
      // this.$emit('update:date', this.datePreset)
      this.$emit('setPresetDate', this.datePreset)
    },
    selectCurrentWeek() {
      this.datePreset = [moment().startOf('isoWeek').format('YYYY-MM-DD'), this.currentDay]
      this.$emit('setPresetDate', this.datePreset)
    },
    selectLastWeek() {
      this.datePreset = [moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'), this.currentDay]
      this.$emit('setPresetDate', this.datePreset)
    },
    select2LastWeek() {
      this.datePreset = [moment().subtract(2, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'), this.currentDay]
      this.$emit('setPresetDate', this.datePreset)
    },
    select10LastDays() {
      this.datePreset = [moment().subtract(10, 'days').format('YYYY-MM-DD'), this.currentDay]
      this.$emit('setPresetDate', this.datePreset)
    },
    selectCurrentMonth() {
      this.datePreset = [moment().startOf('month').format('YYYY-MM-DD'), this.currentDay]
      this.$emit('setPresetDate', this.datePreset)
    },
    selectLastMonth() {
      this.datePreset = [
        moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
        moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
      ]
      this.$emit('setPresetDate', this.datePreset)
    },
    select3LastMonth() {
      this.datePreset = [moment().subtract(3, 'months').startOf('month').format('YYYY-MM-DD'), this.currentDay]
      this.$emit('setPresetDate', this.datePreset)
    },
    selectAllTime() {
      this.datePreset = ['1990-01-01', this.currentDay]
      this.$emit('setPresetDate', this.datePreset)
    },
  },
}
</script>

<style scoped lang="scss">
.presets {
  //border-right: 1px solid #084b94;
  padding: 15px;
}
</style>
